import {
  AssignmentType,
  SearchEstateSort
} from '../../../../__types__/generated/cluster';
import { IConfig } from '../types/Index';

export const getConfigDefault = (): IConfig => {
  return {
    total: null,
    pagnation: { limit: 50, page: 0 },
    price: {
      min: 1000000,
      max: 10000000,
      step: 100000
    },
    size: {
      min: 30,
      max: 400,
      step: 5
    },
    published: true,
    sold: {
      selectedValue: null,
      options: [
        {
          label: 'Eiendom til salgs',
          value: '0'
        },
        {
          label: 'Solgte eiendommer',
          value: '1'
        }
      ]
    },
    counties: [],
    municipalities: [],
    suburbs: [],
    sort: {
      selectedValue: SearchEstateSort.PublishedNewOld,
      options: [
        {
          label: 'Publisert: Ny-Gammel',
          value: SearchEstateSort.PublishedNewOld
        },
        {
          label: 'Publisert: Gammel-Ny',
          value: SearchEstateSort.PublishedOldNew
        },
        { label: 'Pris: Høy-Lav', value: SearchEstateSort.PriceHighLow },
        { label: 'Pris: Lav-Høy', value: SearchEstateSort.PriceLowHigh },
        { label: 'Størrelse: Høy-Lav', value: SearchEstateSort.SizeHighLow },
        { label: 'Størrelse: Lav-Høy', value: SearchEstateSort.SizeLowHigh },
        { label: 'Visning i dag', value: SearchEstateSort.ShowingToday },
        { label: 'Visning i morgen', value: SearchEstateSort.ShowingTomorrow },
        {
          label: 'Visning denne uken',
          value: SearchEstateSort.ShowingThisWeek
        },
        { label: 'Nylig solgt', value: SearchEstateSort.RecentlySold }
      ]
    },
    estateTypes: [],
    assignmentTypes: [
      {
        label: 'Brukt',
        count: 0,
        value: [
          AssignmentType.Buy,
          AssignmentType.Commercial,
          AssignmentType.DeceasedEstate,
          AssignmentType.ForcedSale,
          AssignmentType.Plot,
          AssignmentType.Foreclosure,
          AssignmentType.OffMarket,
          AssignmentType.ReSale,
          AssignmentType.Sale,
          AssignmentType.Plot,
          AssignmentType.Settlement
        ]
      },
      {
        label: 'Nybygg',
        count: 0,
        value: [
          AssignmentType.NewDevelopment,
          AssignmentType.NewDevelopmentCommercial,
          AssignmentType.NewDevelopmentLeisure
        ]
      },
      { label: 'Fritid', count: 0, value: [AssignmentType.Leisure] }
    ]
  };
};
